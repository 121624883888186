<template>
    <div class="gainers">
        <Loader :loader="loader" />
        <h1 class="center-align">Top 50 Coins Gainers Today 🔥</h1>
        <div class="row">
            <div
                v-for="data of topGainers.slice(0, 50)"
                :key="data.id"
                class="col"
            >
                <router-link 
                    :to="{name:'Currency', params: {name: data.id}}"
                >
                    <CoinBox 
                        :data="data" 
                        :chartHeight="chartHeight"
                    />
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from '../components/Loader.vue'
import CoinBox from "../components/CoinBox.vue";

export default {
    name: "Gainers",
    data () {
        return {
            chartHeight: 100
        }
    },
    components: {
        Loader,
        CoinBox
    },
    computed: {
        ...mapGetters(['allCoinsApi', 'loader']),
        topGainers: function() {
            return this.allCoinsApi.slice(0).sort((a, b) => b.price_change_percentage_24h - a.price_change_percentage_24h);
        },
    },
    methods: {
        ...mapActions(["getCoinsData"]),
    },
    async mounted() {
        this.getCoinsData();
    },
};
</script>

<style>
.gainers h1 {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.gainers .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
</style>
