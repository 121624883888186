<template>
    <div id="app">
        <NavMenu/>
        <div>
            <router-view></router-view>
        </div>
        <Footer />
    </div>
</template>

<script>
import NavMenu from "./components/NavMenu.vue"
import Footer from "./components/Footer.vue"

export default {
    name: "App",
    components: {
        NavMenu,
        Footer
    },
};
</script>

<style>
.color-red {
    color: #F44336;
}

.color-green {
    color: #26a69a;
}

table {
    /* background-color: #2d323b; old */
    background-color: #222531;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}

tr {
    border-bottom: 1px solid rgba(0,0,0,0.12);
}

tbody:hover {
    background-color: rgb(255 255 255 / 3%);
}

strong, span {
    cursor: default;
}

img {
    width: 100%;
    height: auto;
}

input {
    color: #fff;
}

.caret {
    fill: #fff !important;
}

tbody:hover {
    background-color: transparent !important;
}

table tr:hover {
    background-color: hsla(0,0%,100%,.05);
}

.material-icons {
    cursor: default;
}

.pt50 {
    padding-top: 50px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

a {
    color: #26a69a !important;
}

.dn {
    display: none !important;
}

.pl0 {
    padding-left: 0px !important;
}

.pr0 {
    padding-right: 0px !important;
}

.ml0 {
    margin-left: 0px !important;
}

.mr0 {
    margin-right: 0px !important;
}

.score-yellow p,
.score-green p {
    color: #ffffff;
    display: inline-block;
    padding: 5px 15px;
    margin: 0px;
    border-radius: 16px;
}

.score-green p {
    background-color: #26a69a;
}

.score-yellow p {
    background-color: rgb(245, 163, 65);
}

.btnActive {
    background-color: rgba(38, 166, 153, 0.5) !important;
}

.button {
    background-color: #26a69a;
    color: #fff;
    padding: 5px 5px;
    border-radius: 3px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    border: 0px;
}
.button:hover {
    background-color: rgba(38,166,153,0.8);
}

.remove-rowsMargin {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
}
</style>
