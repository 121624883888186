<template>
	<footer class="footer">
		<p>Copyright © {{ year }} CoinsToday. All rights reserved.</p>
	</footer>
</template>

<script>
export default {
	name: 'Footer',
	data() {
		return {
			year: new Date().getFullYear(),
		}
	}
}
</script>

<style>
footer.footer {
	padding: 10px 0px;
	background-color: #18191a;
	text-align: center;
	margin-top: 50px;
	font-size: 13px;
	font-weight: 400;
	box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}
</style>