import Vue from 'vue'
import store from './store'
import VueRouter from "vue-router";
import router from './router'
import App from './App.vue'
import "materialize-css/dist/css/materialize.min.css";
import "material-design-icons/iconfont/material-icons.css";
import "materialize-css/dist/js/materialize.min";
import HighchartsVue from "highcharts-vue";

Vue.config.productionTip = false;

Vue.use(HighchartsVue);
Vue.use(VueRouter);

Vue.config.productionTip = false

Vue.filter("toLocaleString", function(value) {
   if(value > 1 ) {
      return value.toLocaleString("de-DE");
   }
   if(value < 0 ) {
      return value.toFixed(2);
   }
   return value;
});

Vue.filter("nFormatter", function(value) {
     if (value >= 1000000000) {
        return (value / 1000000000).toFixed(2).replace(/\.0$/, '') + 'B';
     }
     if (value >= 1000000) {
        return (value / 1000000).toFixed(2).replace(/\.0$/, '') + 'M';
     }
     if (value >= 1000) {
        return (value / 1000).toFixed(2).replace(/\.0$/, '') + 'K';
     }
     return value;
});

Vue.filter('capitalizeFirstLetter', function(value) {
    return value.replace(/(?:-| |\b)(\w)/g, function($1) {
        return $1.toUpperCase().replace("-", " ");
    });
});

// Vue.filter('testFilter', function(value) {
//       if(value === 'ethereum') {
//          return 'test'
//       }
//       return value
// });

Vue.filter('urlDomain', function(value) {
      return (new URL(value)).hostname.replace('www.','');
});


new Vue({
  store,
  render: h => h(App),
  router
}).$mount('#app')
