<template>
	<div v-if="loader" class="loader">
        Loading...
    </div>
</template>

<script>
export default {
	name: 'Loader',
	props: ['loader']
}
</script>

<style>
.loader {
    background: #131720;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
</style>