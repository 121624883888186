<template>
  <div class="currency_stats">
    <h2>{{ coinData.name }} Price Statistics</h2>
    <div class="row">
      <div class="col l12">
        <div class="currency_stats__container">
          <ul>
            <li>
              <h3>{{ coinData.name }} Price</h3>
              <span>
                ${{coinData.market_data.current_price.usd | toLocaleString}}
              </span>
            </li>
            <li>
              <h3>Rank</h3>
              <span>#{{ coinData.market_cap_rank }}</span>
            </li>
            <li>
              <h3>Trading Volume</h3>
              <span>${{coinData.market_data.total_volume.usd | toLocaleString}}</span>
            </li>
            <li>
              <h3>Market Cap</h3>
              <span>${{coinData.market_data.market_cap.usd | toLocaleString}}</span>
            </li>
            <li>
              <h3>Market Change 24h</h3>
              <span>
                ${{coinData.market_data.market_cap_change_24h | toLocaleString}}
                <div
                  class="arrow"
                  :class="coinData.market_data.market_cap_change_percentage_24h > 0 ? 'color-green' : 'color-red'">
                  <i class="material-icons">
                    {{coinData.market_data.price_change_percentage_24h > 0 ? "arrow_drop_up" : "arrow_drop_down"}}
                  </i>
                  <strong>{{coinData.market_data.market_cap_change_percentage_24h.toFixed(2)}}%</strong
                  >
                </div>
              </span>
            </li>
            <li>
              <h3>Price Change 24h</h3>
              <span>${{ coinData.market_data.price_change_24h | toLocaleString }} 
                <div class="arrow" :class="coinData.market_data.price_change_percentage_24h > 0 ? 'color-green' : 'color-red'">
                  <i class="material-icons">
                    {{coinData.market_data.price_change_percentage_24h > 0 ? "arrow_drop_up" : "arrow_drop_down"}}
                  </i>
                  <strong>{{ coinData.market_data.price_change_percentage_24h.toFixed(2)}}%</strong>
                </div>
              </span>
            </li>
            <li>
              <h3>24h Low / 24h High</h3>
              <span
                >${{ coinData.market_data.high_24h.usd | toLocaleString }} / ${{coinData.market_data.low_24h.usd | toLocaleString}}
              </span>
            </li>
            <li>
              <h3>All-Time High</h3>
              <span>
                ${{ coinData.market_data.ath.usd | toLocaleString }}
                <div
                  class="arrow"
                  :class="coinData.market_data.ath_change_percentage > 0 ? 'color-green' : 'color-red'"
                >
                  <i class="material-icons">
                    {{coinData.market_data.ath_change_percentage > 0 ? "arrow_drop_up" : "arrow_drop_down"}}
                  </i>
                  <strong>{{ coinData.market_data.ath_change_percentage.usd.toFixed(2)}}%</strong>
                </div>
                {{new Date(coinData.market_data.ath_date.usd).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })
                }}
              </span>
            </li>
            <li>
              <h3>All-Time High</h3>
              <span>
                ${{ coinData.market_data.atl.usd | toLocaleString }}
                <div 
                  class="arrow"
                  :class="coinData.market_data.atl_change_percentage > 0 ? 'color-green' : 'color-red'"
                >
                  <i class="material-icons">
                    {{ coinData.market_data.atl_change_percentage > 0 ? "arrow_drop_up" : "arrow_drop_down" }}
                  </i>
                  <strong>
                    {{coinData.market_data.atl_change_percentage.usd.toFixed(2)}}%</strong>
                </div>
                {{
                  new Date(coinData.market_data.atl_date.usd).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })
                }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'CurrencyStats',
  props: ['coinData']
}
</script>


<style lang="scss">
.currency_stats {
  h2 {
    margin-top: 30px !important;
  }
  &__container {
    background-color: #222531;
    border-radius: 5px;
    border: 1px solid rgba(240, 240, 255, 0.1);
    padding: 5px 20px 5px 20px;
    ul {
      padding: 0px;
      margin: 0px;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(158, 158, 158, 0.3);
        padding: 13px 0px;
        &:last-child {
          border-bottom: 0px;
        }
      }
    }
    h3 {
      color: #bbbbbb;
      font-weight: 500;
      margin: 0;
      font-size: 13px;
    }
    span {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      text-align: right;
      .arrow {
        display: flex;
        align-self: center;
        justify-content: right;
      }
    
    }
  }
}
</style>