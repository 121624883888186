<template>
  <div class="gayner-box">
    <div class="gayner-box__header">
      <div class="gayner-box__header-icon">
        <img :src="`${data.image}`" :alt="`${data.name}`" />
      </div>
      <div class="gayner-box__header-info gayner-box__info">
        <div class="gayner-box__info-name">
          <h2>{{ data.name }}</h2>
        </div>
        <div class="gayner-box__info-price">
          <p>${{ data.current_price | toLocaleString }}</p>
        </div>
        <div
          class="gayner-box__info-percentage"
          :class="data.price_change_percentage_24h > 0 ? 'green-bg' : 'red-bg'"
        >
          <i class="material-icons">
            {{
              data.price_change_percentage_24h > 0
                ? "arrow_drop_up"
                : "arrow_drop_down"
            }}
          </i>
          {{ data.price_change_percentage_24h.toFixed(2) }}%
        </div>
      </div>
    </div>
    <div class="gayner-box__footer">
      <div class="body">
        <line-chart
          :chartdata="data.sparkline_in_7d.price"
          :options="data.sparkline_in_7d.price"
          :height="chartHeight"
          :chartColor="data.price_change_percentage_24h"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from "../components/chart/Chart.vue";

export default {
  name: "CoinBox",
  data() {
    return {
      chartColor: "",
    };
  },
  props: ['data','chartHeight'],
  components: {
    LineChart,
  },
};
</script>


<style>
.gayner-box {
  background-color: #222531;
  margin-bottom: 15px;
  border-radius: 20px;
  width: 215px;
  height: 215px;
  padding: 15px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 1px 5px 0 rgb(0 0 0 / 20%);
}

.gayner-box__header {
  display: inline-flex;
  align-items: flex-start;
}

.gayner-box__header-icon img {
  width: 40px;
  margin-right: 15px;
}

.gayner-box__info-name h2 {
  font-size: 14px;
  color: #a1a7bb;
  font-weight: 600;
  margin-top: 3px;
  margin-bottom: 0px;
}

.gayner-box__info-price p {
  margin: 0px;
  color: #fff;
  font-weight: 600;
  font-size: 17px;
  line-height: 33px;
}

.green-bg {
  background-color: #26a69a;
}
.red-bg {
  background-color: #ea3943;
}

.red-bg,
.green-bg {
  padding: 0px 10px 0px 5px;
  border-radius: 20px;
  margin: 0px;
  display: inline-block;
  font-weight: 600;
  font-size: 17px;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  color: #fff;
}

.gayner-box__info-percentage .material-icons {
  font-size: 18px;
}
</style>