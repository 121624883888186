import VueRouter from 'vue-router';
import Home from './pages/Home'
import Gainers from './pages/Gainers';
import Currency from './pages/Currency';

export default new VueRouter({
    routes: [
        {
            path: "",
            component: Home,
            name: 'Home',
            props: true,
        },
        {
            path: "/gainers",
            name: 'Gainers',
            component: Gainers,
        },
        {
            path: "/currency/:name",
            name: 'Currency',
            component: Currency,
        },
    ],
    mode: "history",
});
