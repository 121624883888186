<template>
    <div class="row top-charts">
        <div
            v-for="data of selectFilter.slice(0, 4)"
            :key="data.id"
            class="col s3"
        >
            <div class="top-charts__container">
                <div class="header">
                    <div class="header__img">
                        <img :src="`${data.image}`" :alt="`${data.name}`" />
                    </div>
                    <div class="header__info">
                        <div class="header__info-name">
                            {{ data.name }}
                        </div>
                        <div class="header__info-symbol">
                            {{ data.symbol }}
                        </div>
                    </div>
                </div>
                <div class="body">
                    <line-chart
                        :chartdata="data.sparkline_in_7d.price"
                        :options="data.sparkline_in_7d.price"
                        :height="100"
                        :chartColor="data.price_change_percentage_24h"
                    />
                </div>
                <div class="footer">
                    <div class="footer__price">
                        <strong
                            >${{ data.current_price | toLocaleString }}</strong
                        >
                    </div>
                    <div
                        class="footer__percentages"
                        :class="data.price_change_percentage_24h > 0? 'color-green' : 'color-red'">
                        <strong>{{data.price_change_percentage_24h.toFixed(2)}}%</strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LineChart from "../chart/Chart.vue";

export default {
	name: 'HomeChart',
    data() {
        return {
            chartColor: ''
        }
    },
    components: {
        LineChart,
    },
	props: ['selectFilter'],
};
</script>

<style>
.top-charts__container {
    padding: 25px;
    border-radius: 4px;
    height: 240px;
}

.top-charts__container,
table {
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    font-size: 14px;
    /* background-color: #2d323b; */
    background-color: #222531;

}

.top-charts {
    margin-left: -.75rem;
    margin-right: -.75rem;
}

.top-charts__container .header {
    display: inline-flex;
}

.top-charts__container .header__img {
    width: 45px;
    height: 45px;
    margin-right: 15px;
}

.top-charts__container .header__info-name {
    font-weight: 600;
}

.top-charts__container .header__info-symbol {
    font-size: 14px;
    font-weight: 600;
    color: rgb(255 255 255/53%);
    text-transform: uppercase;
}

.top-charts__container .footer {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items:  center;
    margin-top: 15px;
}

.top-charts__container .footer .footer__price {
    font-size: 19px;
    font-weight: 600;
    text-transform: uppercase;
}

.toolbar .row {
    margin-bottom: 0;
}
</style>
