<template>
    <div class="container">
        <div class="homePage">
            <Loader :loader="loader" />
            <HomeChart :selectFilter="selectFilter" />
            <h1>TOP 100 Coins {{selectValue | capitalizeFirstLetter}} 🔥</h1>
            <div class="toolbar">
                <div class="input-field col">
                  <i class="material-icons prefix">search</i>
                  <input v-model="searchCoin" id="icon_prefix" type="text" class="validate">
                  <label for="icon_prefix">Search Coin</label>
                </div>
                <div class="input-field col">
                    <select v-model="selectValue">
                      <option value="by-market-cap">Market Cap</option>
                      <option value="gainers">Gainers</option>
                    </select>
                </div>
            </div>
            <CryptoList 
                :searchCrypto="searchCrypto"
            />
        </div>
    </div>
</template>

<script>
import CryptoList from '../components/CryptoList.vue'
import HomeChart from '../components/chart/HomeChart.vue'
import Loader from '../components/Loader.vue'
import M from 'materialize-css'
import { mapGetters, mapActions } from "vuex";

export default {
    name: "HomePage",
    data() {
        return {
            searchCoin: '',
            timer: '',
            selectValue: 'by-market-cap',
        }
    },
    components: {
        Loader,
        CryptoList,
        HomeChart
    },
    // created() {
    //     this.getCoinsData();
    //     this.timer = setInterval(this.getCoinsData, 120000);
    // }, 
    methods: {
        ...mapActions(['getCoinsData']),
    },
    computed: {
        ...mapGetters(['allCoinsApi', 'loader']),
        selectFilter: function() {
            if(this.selectValue === 'gainers') {
                return this.allCoinsApi.slice(0).sort((a, b) => b.price_change_percentage_24h - a.price_change_percentage_24h );
            }

            if(this.selectValue === 'by-market-cap') {
                return this.allCoinsApi
            }
            return []
        },
        searchCrypto: function() {
            return this.selectFilter.filter((item) => {
                return item.name.toLowerCase().indexOf(this.searchCoin) !== -1 || item.symbol.toLowerCase().indexOf(this.searchCoin) !== -1;
            });
        },
    },
    async mounted () {
        this.getCoinsData(),
        M.AutoInit()
    }
};
</script>


<style scoped>

h1 {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 0px;
}

.homePage {
    margin-top: 50px;
    position: relative;
}

.toolbar {
    display: flex;
    justify-content: space-between;
}

</style>