<template>
  <div class="converter">
    <h2>{{ coinData.symbol.toUpperCase() }} to USD Converter</h2>
    <div class="converter__container">
      <div class="row">
        <form class="col s12">
          <div class="row">
            <div class="input-field col l12">
              <i class="prefix">
                <img :src="coinData.image.small" :alt="coinData.name" />
              </i>
              <input v-model="exchangeVal" id="icon_prefix" type="number" />
              <label for="icon_prefix" class="active">
                {{ coinData.name }} ({{coinData.symbol.toUpperCase()}})
              </label>
            </div>
          </div>
          <div class="center-align sort-icon">
            <img @click="swap" src="../assets/img/sort.png" alt="sort" />
          </div>
          <div class="row">
            <div class="input-field col l12">
              <i class="prefix">
                <img src="../assets/img/dollar.png" alt="USD" />
              </i>
              <p id="icon_usd">
                ${{exchangeVal * coinData.market_data.current_price.usd | toLocaleString()}}
              </p>
              <label for="icon_usd" class="icon_usd active">USD</label>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "Converter",
  props: ["coinData"],
  data() {
    return {
      exchangeVal: "1",
    };
  },
  methods: {
    swap() {},
  },
};
</script>

<style lang="scss">
.converter {
  img {
    width: 30px;
  }
  &__container {
    // background-color: rgba(255, 255, 255, 0.06);
    background-color: #222531;
    border-radius: 5px;
    border: 1px solid rgba(240, 240, 255, 0.1);
    padding: 30px 20px 15px 20px;
  }
  .input-field.col {
    margin-top: 0;
    margin-bottom: 0;
  }
  input {
    font-weight: bold;
  }
  .sort-icon {
    padding: 10px 0px;
    position: relative;
    img {
      width: 20px;
    }
  }
  #icon_usd {
    color: #fff;
    border-bottom: 1px solid #9e9e9e;
    cursor: auto;
  }
  #icon_usd {
    margin-left: 43px;
    overflow: hidden;
  }
}
</style>