import Vue from "vue";
import Vuex from "vuex";
import coinsApi from './modules/coinsApi';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        coinsApi,
    },
});
