<template>
  <div>
    <Loader :loader="loader" />
    <div v-if="!loader" class="currency">
      <div class="container">
        <div class="currency__header">
          <div class="row">
            <div class="col l6">
              <div class="currency__name">
                <img :src="coinData.image.small" :alt="coinData.name" />
                <h1>
                  {{ coinData.name }}
                  <span>({{ coinData.symbol.toUpperCase() }})</span>
                </h1>
                <span class="currency__rank">#{{ coinData.market_cap_rank }}</span>
              </div>
              <div class="currency__price">
                <p>
                  ${{ coinData.market_data.current_price.usd | toLocaleString }}
                </p>
                <span
                  :class="coinData.market_data.price_change_percentage_24h > 0 ? 'color-green' : 'color-red'">
                  <i class="material-icons">
                    {{coinData.market_data.price_change_percentage_24h > 0 ? "arrow_drop_up" : "arrow_drop_down"}}
                  </i>
                  {{coinData.market_data.price_change_percentage_24h.toFixed(2)}}%
                </span>
              </div>
            </div>
            <div class="col l3">
              <div class="currency__indicators">
                <h3>MARKET CAP</h3>
                <p>
                  ${{ coinData.market_data.market_cap.usd | nFormatter }}
                  <span :class="coinData.market_data.price_change_percentage_24h > 0 ? 'color-green' : 'color-red'">
                    <i class="material-icons">
                      {{coinData.market_data.price_change_percentage_24h > 0 ? "arrow_drop_up" : "arrow_drop_down"}}
                    </i>
                    {{coinData.market_data.market_cap_change_percentage_24h.toFixed(2)}}%
                  </span>
                </p>
              </div>
              <div class="currency__indicators">
                <h3>LOW - HIGH 24H</h3>
                <p>${{ coinData.market_data.low_24h.usd | toLocaleString }} - ${{ coinData.market_data.high_24h.usd | toLocaleString}}</p>
              </div>
            </div>
            <div class="col l3">
              <div class="currency__indicators">
                <h3>Volume 24h</h3>
                <p>${{ coinData.market_data.total_volume.usd | nFormatter }}</p>
              </div>
              <div class="currency__indicators">
                <h3>CUR - MAX SUPPLY</h3>
                <p>$ {{ coinData.market_data.circulating_supply | nFormatter }} - {{coinData.market_data.max_supply ? coinData.market_data.max_supply : "∞" | nFormatter }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row pt50 ml0 mr0">
          <div class="col l8 pl0">
            <div class="currency__chart">
              <div id="chart">
                <CurrencyChart 
                  :chartData="chartData.prices" 
                  :coinData="coinData"
                  @clickTab="clickedTabVal"
                />
              </div>
            </div>
            <div class="currency__info">
              <h2>
                {{ coinData.name }} ({{ coinData.symbol.toUpperCase() }}) Links
              </h2>
              <div class="currency__container">
                <ul>
                  <li v-if="Object.values(this.coinData.platforms)[0] != ''">
                    <div class="currency__title">
                      <h3>Contract</h3>
                    </div>
                    <div class="chip_links">
                      <div class="chip">
                        <div>
                          <p>
                            <span>{{Object.keys(this.coinData.platforms)[0] | capitalizeFirstLetter }}</span>: {{ Object.values(this.coinData.platforms)[0] }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    v-if="coinData.links.homepage.filter((url) => url != '').length">
                    <div class="currency__title">
                      <h3>Website</h3>
                    </div>
                    <div class="chip_links">
                      <div 
                        v-for="coin in coinData.links.homepage.filter((i) => i !== '')"
                        :key="coin.id"
                        class="chip"
                      >
                        <div>
                          <a target="blank_" 
                            :href="coin">{{coin | urlDomain | capitalizeFirstLetter}}
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li v-if="coinData.links.blockchain_site !== 0">
                    <div class="currency__title">
                      <h3>Explorers</h3>
                    </div>
                    <div class="chip_links">
                      <div
                        v-for="coin in coinData.links.blockchain_site.filter((i) => i !== '')"
                        :key="coin.id"
                        class="chip"
                      >
                        <div>
                          <a target="blank_" :href="coin">{{coin | urlDomain | capitalizeFirstLetter}}</a>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    v-if="
                      coinData.links.official_forum_url.filter((url) => url != '').length">
                    <div class="currency__title">
                      <h3>Community</h3>
                    </div>
                    <div class="chip_links">
                      <div v-for="coin in coinData.links.official_forum_url.filter((i) => i !== '')"
                        :key="coin.id"
                        class="chip"
                      >
                        <div>
                          <a target="blank_" :href="coin">{{
                            coin | urlDomain | capitalizeFirstLetter
                          }}</a>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="currency__title">
                      <h3>Social</h3>
                    </div>
                    <div class="chip_links">
                      <div
                        v-if="coinData.links.twitter_screen_name != ''"
                        class="chip"
                      >
                        <div>
                          <a
                            target="blank_"
                            :href="'https://twitter.com/' + coinData.links.twitter_screen_name + ''">
                            <img src="../assets/img/twitter.png" alt="" />
                            Twitter
                          </a>
                        </div>
                      </div>
                      <div
                        v-if="coinData.links.facebook_username != ''"
                        class="chip"
                      >
                        <div>
                          <a
                            target="blank_"
                            :href="'https://www.facebook.com/' + coinData.links.facebook_username + '' " >
                            <img src="../assets/img/facebook-logo.png" alt="" />
                            Facebook
                          </a>
                        </div>
                      </div>
                      <div
                        v-if="coinData.links.telegram_channel_identifier != ''"
                        class="chip"
                      >
                        <div>
                          <a
                            target="blank_"
                            :href=" 'https://t.me/' + coinData.links.telegram_channel_identifier + '' "
                          >
                            <img src="../assets/img/telegram.png" alt="" />
                            Telegram
                          </a>
                        </div>
                      </div>
                      <div
                        v-if="coinData.links.subreddit_url != ''"
                        class="chip"
                      >
                        <div>
                          <a
                            target="blank_"
                            :href="coinData.links.subreddit_url"
                          >
                            <img src="../assets/img/reddit.png" alt="" /> Reddit
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li v-if="coinData.categories.length">
                    <div class="currency__title">
                      <h3>Tags</h3>
                    </div>
                    <div class="chip_links">
                      <div
                        v-for="coin in coinData.categories.filter( (i) => i !== '' )" 
                        :key="coin.id"
                        class="chip"
                      >
                        <div>
                          <a>{{ coin }}</a>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col l4 pr0">
            <Converter :coinData="coinData" />
            <CurrencyStats :coinData="coinData" />
          </div>
        </div>
        <div v-if="coinData.tickers.filter(item => item.target === 'USDT').length" class="currency__exchange" style="overflow-x: auto">
          <h2>
            {{coinData.name}} Markets
          </h2>
          <table>
            <thead>
              <tr>
                <th class="list-nr center-align">#</th>
                <th>Exchange</th>
                <th>Pair</th>
                <th class="right-align">Price</th>
                <th class="right-align">24h Volume</th>
                <th class="right-align">Last Traded</th>
                <th class="center-align">Trust Score</th>
              </tr>
            </thead>
            <tbody>
              <tr 
                v-for="(data, index) in coinData.tickers.filter(item => item.target === 'USDT').slice(0, 5)" 
                :key="index"
              >
                <td class="list-nr center-align">{{ index+1 }}</td>
                <td class="crypto-names">
                  <strong>
                    <!-- <img :src="data.image" :alt="data.name" /> -->
                    {{ data.market.name }}
                  </strong>
                </td>
                <td class="crypto-symbols">
                  <span class="crypto-symbol">{{ data.base }} / {{ data.target }}</span>
                </td>
                <td class="crypto-price right-align">
                  <strong>${{ data.converted_last.usd | toLocaleString }}</strong>
                </td>
                <td class="crypto-high_24h right-align">
                  <strong>${{ data.converted_volume.usd | toLocaleString }}</strong>
                </td>
                <td class="crypto-high_24h right-align">
                  <strong>Recently</strong>
                </td>
                <td class="crypto-price_change center-align" :class="data.trust_score === 'green' ? 'score-green' : 'score-yellow'">
                    <p>{{data.trust_score === 'green' ? 'High' : 'Moderate'}}</p>                    
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="currency__price-data">
          <h2>{{ coinData.name }} Coin Price & Market Data</h2>
          <p>
            <strong>{{ coinData.name }}</strong> price today is ${{
              coinData.market_data.current_price.usd | toLocaleString
            }}
            with a 24-hour trading volume of ${{
              coinData.market_data.total_volume.usd | toLocaleString
            }}. {{ coinData.symbol.toUpperCase() }} price is
            {{
              coinData.market_data.price_change_percentage_24h > 0
                ? "up"
                : "down"
            }}
            <strong
              :class="
                coinData.market_data.price_change_percentage_24h > 0
                  ? 'color-green'
                  : 'color-red'
              "
              >{{
                coinData.market_data.price_change_percentage_24h.toFixed(2)
              }}%</strong
            >
            in the last 24 hours. It has a circulating supply of
            {{ coinData.market_data.circulating_supply | nFormatter }}
            {{ coinData.symbol.toUpperCase() }} coins and a total supply of
            {{
              coinData.market_data.total_supply
                ? coinData.market_data.total_supply
                : "∞" | nFormatter
            }}. If you are looking to buy or sell {{ coinData.name }},
            <a
              target="blank_"
              :href="
                'https://www.binance.com/en/trade/' +
                coinData.symbol.replace(/\s/g, '-').toUpperCase() +
                '_USDT?ref=16123727'
              "
              >Binance</a
            >
            is currently the most active exchange.
          </p>
        </div>
        <div class="currency_about">
          <h2>About {{ coinData.name }}</h2>
          <div class="currency__description" v-html="coinDescriprion"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../components/Loader.vue";
import Converter from "../components/Converter.vue";
import CurrencyStats from "../components/CurrencyStats.vue";
import CurrencyChart from "../components/chart/CurrencyChart2.vue";


export default {
  name: "Currency",
  data() {
    return {
      coin: "",
      coinData: [],
      chartData: [],
      exchangeData: [],
      coinDataId: this.$route.params.name,
      loader: true,
      exchangeVal: "1",
      coinDescriprion: "",
      chartPeriode: 1,
    };
  },
  components: {
    Loader,
    CurrencyChart,
    Converter,
    CurrencyStats,
  },
  methods: {
    async getCoinData() {
      let coinAPI =`https://api.coingecko.com/api/v3/coins/${this.coinDataId}?tickers=true&market_data=true&community_data=true&developer_data=false&sparkline=true`;
        await axios.get(coinAPI)
        .then((res)=> {
          this.coinData = res.data;
          this.changeDescData();
        })
        .catch((errors) => {
          console.error(errors);
          this.loader = false;
        });
    },
    async getChartData() {
      let chartAPI =`https://api.coingecko.com/api/v3/coins/${this.coinDataId}/market_chart?vs_currency=usd&days=${this.chartPeriode}`;
        await axios.get(chartAPI)
        .then((res)=> {
          this.chartData = res.data;
        })
        .catch((errors) => {
          console.error(errors);
          this.loader = false;
        });
    },
    async getExchangeData() {
      let exchangeAPI =`https://api.coingecko.com/api/v3/exchanges`;
        await axios.get(exchangeAPI)
        .then((res)=> {
          this.exchangeData = res.data;
        })
        .catch((errors) => {
          console.error(errors);
          this.loader = false;
        });
    },
    changeDescData: function() {
      const description = this.coinData.description.en;
      this.coinDescriprion = description
        .replace(new RegExp("<[^>]*>", "g"), "")
        .replaceAll("\n", "<br/>");
    },
    clickedTabVal: function(clickTab) {
      this.chartPeriode = clickTab;
      this.getChartData();
    }
  },
  async mounted() {
    await this.getChartData();
    await this.getCoinData();
    await this.getExchangeData();
    this.loader = false;
  },
};
</script>

<style lang="scss">
.currency {
  h2 {
    font-size: 22px;
    margin: 0px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  &__header {
    background-color: #222531;
    padding-top: 20px;
    border-radius: 5px;
    border: 1px solid rgba(240, 240, 255, 0.1);
  }
  .row {
    margin-bottom: 0px;
  }
  &__name {
    display: inline-flex;
    align-items: center;
    width: 100%;
    img {
      width: 50px;
    }
    h1 {
      margin: 0px;
      font-size: 28px;
      margin-left: 15px;
    }
  }
  &__rank {
    background-color: #26a69a;
    padding: 2px 4px;
    border-radius: 3px;
    font-weight: 500;
    margin-left: 10px;
  }
  &__price {
    display: inline-flex;
    p {
      font-size: 30px;
      font-weight: 500;
      margin-left: 65px;
      margin: 0px;
      margin-left: 65px;
    }
    span {
      align-items: center;
      display: flex;
      margin-left: 15px;
      font-weight: 600;
    }
  }
  &__indicators {
    margin-bottom: 15px;
    h3 {
      color: #ada9a9;
      font-size: 10px;
      text-transform: uppercase;
      margin: 0px;
      font-weight: 600;
      margin-bottom: 5px;
    }
    p {
      margin: 0;
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      span {
        display: inline-flex;
        align-items: center;
        font-size: 11px;
        margin-left: 10px;
        font-weight: 500;
        i {
          font-size: 19px;
        }
      }
    }
  }
  &__info {
    h2 {
      border-bottom: 0px !important;
      padding-bottom: 0px !important;
    }
  }
  &__info,
  &__price-data,
  &_about {
    h2 {
      border-bottom: 1px solid rgba(158, 158, 158, 0.3);
      padding-bottom: 10px;
      margin-top: 30px;
    }
    font-size: 14px;
  }

  &__container {
    background-color: #222531;
    border-radius: 5px;
    border: 1px solid rgba(240, 240, 255, 0.1);
    padding: 5px 20px 5px 20px;
    .chip {
      background-color: #26a69a;
      a {
        color: #ffffff !important;
        display: inline-flex;
        align-items: center;
        img {
          width: 100%;
          height: 20px;
          margin-right: 5px;
        }
      }
      &_links {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
      }
    }
    ul {
      margin: 0px;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(158, 158, 158, 0.3);
        padding: 13px 0px;
        &:last-child {
          border-bottom: 0px;
        }
      }
    }
    h3 {
      color: #bbbbbb;
      font-weight: 500;
      margin: 0;
      font-size: 13px;
    }
    span {
      font-weight: 600;
    }
    p {
      margin: 0;
      padding: 0;
      color: #fff;
    }
  }
  &__title {
    width: 200px;
  }
  &__exchange {
    h2 {
      margin-top: 30px;
    }
  }
  table {
    background-color: #222531;
    border-radius: 5px;
    border: 1px solid rgba(240, 240, 255, 0.1);
    border-collapse: separate;
  }
}
</style>