<script>
import { Line } from "vue-chartjs";

export default {
    name: 'LineChart',
    data() {
        return {
            gradient: null
        }
    },
    extends: Line,
    props: [ 'chartdata', 'options', 'chartColor'],
    mounted() {
        this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 25, 0, 300)

        if ( this.chartColor > 0 ) {
            this.gradient.addColorStop(0, 'rgba(38, 166, 154, 0.7)')
            this.gradient.addColorStop(0.25, 'rgba(38, 166, 154, 0.1)');
            this.gradient.addColorStop(1, 'rgba(38, 166, 154, 0)');
        } 

        if(this.chartColor < 0) {
            this.gradient.addColorStop(0, 'rgba(244, 67, 54, 0.7)')
            this.gradient.addColorStop(0.25, 'rgba(244, 67, 54, 0.1)');
            this.gradient.addColorStop(1, 'rgba(244, 67, 54, 0)');
        }

        this.renderChart(
            {
                labels: this.chartdata,
                datasets: [
                    {
                        label: false,
                        data: this.options,
                        backgroundColor: this.gradient,
                        borderColor: this.chartColor > 0 ? '#26a69a' : '#F44336',
                        borderWidth: 1.5,
                        pointRadius: 0,
                    },
                ],
            },
            {
                tooltips: {
                    enabled: false,
                },
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [{ display: false }],
                    yAxes: [{ display: false }],
                },
                maintainAspectRatio: false,
            }
        );
    },
};
</script>

