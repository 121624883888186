<template>
    <div>
      <nav class="nav-menu">
        <div class="nav-wrapper container">
          <router-link class="nav-logo" to="/">
                <img class="responsive-img" src="../assets/img/logo.png" alt="">
            </router-link>
          <a href="#" data-target="mobile-demo" class="sidenav-trigger"><i class="material-icons">menu</i></a>
          <ul class="right hide-on-med-and-down">
            <li>
                <router-link to="/">Home</router-link>
            </li>
            <li>
                <router-link to="/gainers">Gainers</router-link>
            </li>
          </ul>
        </div>
        <div class="price-update"></div>
      </nav>

      <ul class="sidenav" id="mobile-demo">
            <li>
                <router-link to="/">Home</router-link>
            </li>
            <li>
                <router-link to="/gainers">Gainers</router-link>
            </li>
      </ul>
    </div>
</template>

<script>

import M from 'materialize-css'

export default {
    name: "NavMenu",
    data() {
        return {
            counter: 0
        };
    },
    watch: {
        counter: {
            handler() {
               setTimeout(() => {
                    this.counter++;
                }, 1000);
            },
            immediate: true, 
        },
    },
    mounted() {
      M.AutoInit(); // That way, it is only initialized when the component is mounted
    }
};
</script>

<style>
.nav-menu {
    background-color: #18191a !important;
    z-index: 3;
    position: relative;
    margin-bottom: 50px;
}

.price-update {  
  animation: slideInFromLeft;
  animation-duration: 120s;
  background: #26a69a;
  animation-iteration-count:infinite;
  animation-timing-function: linear;
  padding: 1.5px;
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

.nav-logo {
    display: inline-block;
    height: 100%;
}

.nav-logo img {
    width: 160px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.nav ul a {
    font-weight: 500;
}
</style>
