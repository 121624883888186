<template>
  <div>
    <h2>
      {{ coinData.name }} ({{ coinData.symbol.toUpperCase() }}) Price Chart
    </h2>
    <div class="chart_header">
      <div class="chart_tabs">
        <ul>
          <li class="chart_tabs__list"><button @click="$emit('clickTab', 1), activeBtn = 'btn1'" class="button waves-light" :class="{btnActive: activeBtn === 'btn1'}">24h</button></li>
          <li class="chart_tabs__list"><button @click="$emit('clickTab', 7), activeBtn = 'btn2'" class="button waves-light" :class="{btnActive: activeBtn === 'btn2'}">7D</button></li>
          <li class="chart_tabs__list"><button @click="$emit('clickTab', 14), activeBtn = 'btn3'" class="button waves-light" :class="{btnActive: activeBtn === 'btn3'}">14D</button></li>
          <li class="chart_tabs__list"><button @click="$emit('clickTab', 90), activeBtn = 'btn4'" class="button waves-light" :class="{btnActive: activeBtn === 'btn4'}">3M</button></li>
          <li class="chart_tabs__list"><button @click="$emit('clickTab', 180), activeBtn = 'btn5'" class="button waves-light" :class="{btnActive: activeBtn === 'btn5'}">6M</button></li>
          <li class="chart_tabs__list"><button @click="$emit('clickTab', 365), activeBtn = 'btn6'" class="button waves-light" :class="{btnActive: activeBtn === 'btn6'}">1Y</button></li>
          <li class="chart_tabs__list"><button @click="$emit('clickTab', 'max'), activeBtn = 'btn7'" class="button waves-light" :class="{btnActive: activeBtn === 'btn7'}">Max</button></li>
        </ul>
      </div>
    </div>
    <highcharts
      :constructor-type="'stockChart'"
      :options="chartOptions"
    ></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import stockInit from "highcharts/modules/stock";


stockInit(Highcharts);
exportingInit(Highcharts);

export default {
  props: ["chartData", "coinData"],
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      activeBtn: 'btn1',
      chartOptions: {
        chart: {
          backgroundColor: "#131720",
          height: 400,
          // events: {
          //   load: function () {
          //     this.series.forEach(function (series) {
          //       series.points[series.points.length - 1].update({
          //         dataLabels: {
          //           enabled: true,
          //           x: 500,
          //         },
          //       });
          //     });
          //   },
          // },
        },
        credits: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        legend: {
          layout: "horizontal",
        },
        navigator: {
          enabled: false,
          adaptToUpdatedData: false,
          maskFill: "rgba(38,166,154,0.2)",
          outlineWidth: 0,
          handles: {
            backgroundColor: "rgba(38,166,154,0.2)",
            borderColor: "rgba(38,166,154)",
          },
          series: {
            lineColor: "rgba(38,166,154)",
          },
        },
        exporting: {
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
        },
        xAxis: {
          lineColor: "rgba(204, 204, 204, 0.5)",
          tickColor: "rgba(204, 204, 204, 0.5)",
          type: "datetime",
          crosshair: {
            color: "#93979e",
            dashStyle: "shortdot",
          },
          labels: {
            y: 25,
          },
        },
        yAxis: {
          // lineColor: 'rgba(204, 204, 204, 0.5)',
          // lineWidth: 1,
          gridLineColor: "rgba(255, 255, 255, 0.06)",
          gridLineDashStyle: "Solid",
          opposite: true, // Y left or roght
          showLastLabel: true,
          crosshair: {
            color: "#93979e",
            dashStyle: "shortdot",
          },
          labels: {
            align: "left",
            x: 10,
            y: 3,
            format: "{value:.,0f}",
          },
        },
        rangeSelector: {
          inputEnabled: false,
          enabled: false,
          allButtonsEnabled: true,
          selected: 0,
          buttons: [{
              type: 'day',
              count: 1,
              text: '14d'
          }, {
              type: 'month',
              count: 3,
              text: '3m',
              title: 'View 3 months'
          }, {
              type: 'month',
              count: 6,
              text: '6m',
              title: 'View 6 months'
          },{
              type: 'year',
              count: 1,
              text: '1y',
              title: 'View 1 year'
          }, {
              type: 'all',
              text: 'All',
              title: 'View all'
          }]
        },
        tooltip: {
          followPointer:true,
          // followTouchMove: true,
          enabled: true,
          xDateFormat: "%b %d %Y %H:%M%p",
          shared: true,
          split: false,
          crosshairs: true,
          padding: 15,
          color: "#FFFFFF",
          valuePrefix: "$",
          useHTML: true,
          fontSize: "25px",
          borderColor: "rgba(0,0,0, 0)",
          backgroundColor: "#000000",
          borderRadius: 8,
          headerShape: true,
          pointFormatter: function () {
            var value = this.y;
            if (value >= 1) {
              value = value.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              });
              return (
                "<span>" +
                this.series.name +
                "</span>: <b>$" +
                value +
                "</b><br />"
              );
            }
            if (value < 1) {
              var fractions = value.toString().match(/(\.0*)/)[0].length - 1;
              return (
                "<span>" +
                this.series.name +
                "</span>: <b>$" +
                value.toFixed(fractions + 2) +
                "</b><br />"
              );
            }
          },
          style: {
            shadow: false,
            color: "#ffffff",
            cursor: "default",
          },
        },
        plotOptions: {
          area: {
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: [
                [0, Highcharts.getOptions().colors[0]],
                [
                  1,
                  Highcharts.color(Highcharts.getOptions().colors[0])
                    .setOpacity(0)
                    .get("rgba"),
                ],
              ],
            },
            marker: {
              radius: 2,
            },
            lineWidth: 1,
            states: {
              hover: {
                lineWidth: 1,
              },
            },
            threshold: null,
          },
        },
        series: [
          {
            name: "Price",
            data: this.chartData,
            lineColor: "rgba(38,166,154)",
            animation: false,
            shadow: false,
            // type: 'area'
          },
        ],
      },
    }
  },
  watch: {
    chartData() {
      this.chartOptions.series[0].data = this.chartData;
    }
  },
}
</script>

<style lang="scss">
.chart_header {
  display: inline-flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: flex-end;
  width: 100%;
  h2 {
    margin-bottom: 0px !important;
  }
  .chart_tabs {
    display: inline-flex;
    margin-right: 10px;
    ul {
      display: inline-flex;
      margin: 0px;
      li {
        .button {
          padding: 5px 4px;
          width: 40px;
          border-radius: 3px;
          margin-left: 5px;
          font-size: 12px;
        }
        .btnActive {
          pointer-events: none;
        }
      }
    }
  }
}
</style>
