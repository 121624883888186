<template>
    <div style="overflow-x:auto;">
        <table>
            <thead>
                <tr>
                    <th class="list-nr">#</th>
                    <th>Name</th>
                    <th></th>
                    <th class="right-align">Price</th>
                    <th class="right-align">24h Change</th>
                    <th class="right-align">24h High</th>
                    <th class="right-align">24h Low</th>
                    <th class="right-align">ATH</th>
                    <th class="right-align">Market Cap</th>
                    <th class="center-align">Trade</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(data, index) of searchCrypto" 
                    :key="index"
                >
                    <td class="list-nr">{{data.market_cap_rank}}</td>
                    <td class="crypto-names">
                        <router-link :to="{name:'Currency', params: {name: data.id}}">
                            <strong>
                                <img 
                                    :src="data.image" 
                                    :alt="data.name"
                                >
                                {{ data.name }}
                            </strong>
                        </router-link>
                    </td>
                    <td class="crypto-symbols">
                        <router-link :to="{name:'Currency', params: {name: data.id}}">
                            <span class="crypto-symbol">{{ data.symbol }}</span>
                        </router-link>
                    </td>
                    <td  class="crypto-price right-align">
                        <strong>${{ data.current_price | toLocaleString  }}</strong>
                    </td>
                    <td class="crypto-price_change right-align" :class="data.price_change_percentage_24h > 0 ? 'color-green' : 'color-red'">
                        <div class="crypto-price_change__container">
                            <i class="material-icons">{{data.price_change_percentage_24h > 0 ? "trending_up" : "trending_down" }}</i>
                            <strong>{{ data.price_change_percentage_24h.toFixed(2) }}%</strong>
                        </div>
                    </td>
                    <td class="crypto-high_24h right-align">
                        <strong>${{ data.high_24h | toLocaleString }}</strong>
                    </td>
                    <td class="crypto-low_24h right-align">
                        <strong>${{ data.low_24h | toLocaleString }}</strong>
                    </td>
                    <td  class="crypto-ath right-align">
                        <strong>${{ data.ath | toLocaleString  }}</strong>
                    </td>
                    <td  class="crypto-marketcap right-align">
                        <strong>${{ data.market_cap | nFormatter  }}</strong>
                    </td>
                    <td class="info-link center-align">
                         <a target="blank_" class="icon-button" :href="'https://www.binance.com/en/trade/'+ data.symbol.replace(/\s/g, '-').toUpperCase()+'_USDT?layout=pro&ref=16123727&type=spot'"> <!-- ? -->
                            <img src="../assets/img/binance-logo.png" :alt="`${data.name}`">
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'CryptoList',
    props: ['searchCrypto'],
}
</script>

<style scoped>

table {
    font-size: 14px;
    border-radius: 4px;
}

.list-nr {
    width: 40px;
    text-align: center;
}

.crypto-names {
    width: 200px;
    cursor: pointer;
}

.crypto-names strong {
    display: flex;
    align-items: center;
    color: #fff;
    cursor: pointer;
}

.crypto-names img {
    width: 40px;
    height: 40px;
    margin-right: 30px;
}
.crypto-names .crypto-name {
    font-size: 16px;
    margin-right: 10px;
}

.crypto-symbols,
.crypto-symbols span,
.crypto-names a {
    cursor: pointer;
}

.crypto-symbol {
    text-transform: uppercase;
    font-size: 14px;
    color: #fff;
}

.crypto-price_change__container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.crypto-price_change__container .material-icons{
    margin-right: 10px;
}

.info-link img {
    width: 25px;
    height: 25px;
    position: relative;
}

.icon-button:hover {
    background-color: rgba(255,255,255,.2);
}

.icon-button {
    border-radius: 50%;
    transition: background-color .4s;
    padding: 10px;
    margin: 0;
    width: 24px;
    height: 24px;
    background-color: transparent;
    display: inline-block;
    box-sizing: content-box;
}

</style>