import axios from "axios";

export default {
    state: {
        coinsApi: [],
        loader: false,
        interval: null,
    },
    mutations: {
        updateCoinsApi(state, coinsApi) {
            state.coinsApi = coinsApi;
        },
        loader(state, newLodaing) {
            state.loader = newLodaing;
        },
    },
    actions: {
        async getCoinsData(ctx) {
            ctx.commit("loader", true);
            try {
                const res = await axios.get(
                    "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=true&price_change_percentage=1h,24h,7d,30d,1y"
                );
                const coinsApi = res.data;
                ctx.commit("updateCoinsApi", coinsApi);
                ctx.commit("loader", false);
            } catch (e) {
                console.error(e);
            }
        },
    },
    getters: {
        allCoinsApi(state) {
            return state.coinsApi;
        },
        loader(state) {
            return state.loader;
        },
    },
};
